@font-face {
  font-family: "Prima Sans";
  src: local("Prima Sans"),
    url(/static/files/PrimaSansBT-Roman.83bee83e.otf) format("opentype");
}

#root {
  background-color: black;
  /* height: 100%; */
  width: 100%;
  position: absolute;
  left: 0;
  overflow: hidden;
  display: flex;
  flex: 1;
}

html {
  background-color: black;
}
